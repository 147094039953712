<template>
  <div class="flex flex-row">
    <div class="flex items-center h-5">
      <input
        id="comments"
        aria-describedby="comments-description"
        name="comments"
        type="checkbox"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      >
    </div>
    <div class="ml-2 text-sm">
      <label
        for="comments"
        class="font-medium text-gray-700"
      >Comments</label>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>