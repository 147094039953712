<template>
  <div class="flex flex-col">
    <div class="flex text-xs text-gray-500 mb-2">
      DOWNLOAD REPORTS
    </div>

    <div class="flex flex-row items-center justify-between mb-8">
      <div class="flex flex-row gap-4 items-center">
        <DropDown />

        <DropDown />

        <Checkbox />
      </div>

      <div class="flex flex-row gap-4 items-center">
        <Button class="flex">
          <template #default>
            <div class="inline-flex flex-row items-center">
              <span>
                Download PDF
              </span>
            </div>
          </template>
        </Button>

        <Button class="flex">
          <template #default>
            <div class="inline-flex flex-row items-center">
              <span>
                Download CSV
              </span>
            </div>
          </template>
        </Button>
      </div>
    </div>

    <Card class="flex mb-6">
      <template #title>
        Exposure over Time
      </template>

      <template #content>
        <LineChart />
      </template>
    </Card>

    <Card class="flex flex-col">
      <template #title>
        Exposure over Time
      </template>

      <template #content>
        <DataTable />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import DropDown from '@/components/DropDown.vue';
import Checkbox from '@/components/Checkbox.vue';
import Button from '@/components/Button.vue';
import DataTable from '@/components/DataTable.vue';
import LineChart from '@/components/LineChart.vue';

export default {
  components: {
    DropDown,
    Checkbox,
    Button,
    Card,
    DataTable,
    LineChart
  },

  data: () => ({
  }),
}
</script>
